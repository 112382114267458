<template>
  <header>
    <div class="header_bar">
      <router-link to="/">
        <img src="@/assets/logo.png" alt="Aviator game" class="logo">
      </router-link>
      <nav class="pages">
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/news">News</router-link></li>
          <li>
            <a href="https://gacricket1.com/" target="_blank" rel="noopener noreferrer">Start Betting</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'GlobalHeader'
};
</script>

<style scoped>
header {
  background: radial-gradient(ellipse at 35% 80%, #0c3b61, #082b48);
}

.header_bar {
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 50%;
}

.logo {
  height: 3.8em;
  width: 3.8em;
}

.pages {
  color: rgb(250, 250, 250);
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1.5em;
  list-style: none;
  text-shadow: 0 0 0.08em #575757, 0 0 0.08em #575757, 0 0 0.08em #575757, 0 0 0.08em #575757;
}

.pages ul {
  display: flex;
  list-style: none;
  gap: 3em;
}

.pages a {
  text-decoration: none;
  color: inherit;
  transition-property: color;
  transition-timing-function: ease;
  transition-duration: 0.6s;
}

.pages a:hover {
  color: #a0d1f9;
}
</style>