<template>
  <footer>
    <ul class="list">
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/news">News</router-link></li>
      <li>
        <a href="https://gacricket1.com/" target="_blank" rel="noopener noreferrer">Start Betting</a>
      </li>
    </ul>
    <p>Copyright © 2024 Skyrocket. All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: 'GlobalFooter'
};
</script>

<style scoped>
footer {
  background: radial-gradient(ellipse at 50% 60%, #135387, #0c3b61);
  background-color: #135387;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  padding: 1em;
  text-align: center;
}

footer ul {
  list-style: none;
  padding: 0;
}

footer p {
  font-family: Arial, sans-serif;
  font-size: 0.9em;
  color: rgb(230, 230, 230);
  letter-spacing: 0.05em;
}

.list {
  width: 40%;
  margin: 0 auto;
  justify-content: center;
  display: flex;
}

.list a {
  text-decoration: none;
  color: inherit;
  transition-property: color;
  transition-timing-function: ease;
  transition-duration: 0.6s;
}

.list li {
  color: rgb(250, 250, 250);
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1.5em;
  text-shadow: 0 0 0.08em #575757, 0 0 0.08em #575757, 0 0 0.08em #575757, 0 0 0.08em #575757;
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 1em;
}

.list a:hover {
  color: #a0d1f9;
}
</style>