<template>
  <main>
    <section class="title">
      <h1>Step Up Your Game with the</h1>
      <h1>Best Sports Betting Platform Online</h1>
    </section>
    <section class="section_1">
      <p>Today's sports betting heightens the thrill of every game.
        GUGOBET offers diverse options and expert tips on its blog
        — start an exciting betting journey with GUGOBET now!
      </p>
      <a href="https://gacricket1.com/" target="_blank" rel="noopener noreferrer">
        <button class="button_bet">Bet on Sport Now</button>
      </a>
    </section>
    <section class="section_2">
      <h2>Our Sports Betting</h2>
    </section>
    <section class="section_3">
      <p>At GUGOBET, we provide a diverse range of sports betting options across multiple leagues and events, ensuring
        you have access to the latest and most comprehensive betting content. Whether you prefer live betting or sports
        exchanges, you can enjoy the excitement of online betting to the fullest with the most competitive odds
        available.</p>
    </section>
    <ul class="list_1">
      <li>
        <span>Cricket</span>
        <img src="@/assets/cricket.jpg" alt="cricket betting game" class="game_pics">
      </li>
      <li>
        <span>Football</span>
        <img src="@/assets/football.jpg" alt="football betting game" class="game_pics">
      </li>
      <li>
        <span>Tennis</span>
        <img src="@/assets/tennis.jpg" alt="tennis betting game" class="game_pics">
      </li>
      <li>
        <span>Basketball</span>
        <img src="@/assets/basketball.jpg" alt="basketball betting game" class="game_pics">
      </li>
    </ul>
    <section class="section_3">
      <p>For more options, check directly on GUGOBET official website.</p>
    </section>
    <section class="section_3">
      <a href="https://gacricket1.com/" target="_blank" rel="noopener noreferrer">
        <button class="button_bet">Bet on Sport</button>
      </a>
    </section>
    <section class="section_2">
      <h2>How to Place Sports Bet</h2>
    </section>
    <div class="list">
      <ul class="list_2">
        <li><span>Visit GUGOBET’s lobby page. *Not yet had the GUGOBET account? Sign up now!!</span></li>
        <li><span>On the top, tap on the sport category.</span></li>
        <li><span>Find the sports you want to place your bets on.</span></li>
        <li><span>Pick the match you are interested in, and check out the betting options, odds, and other
            details.</span>
        </li>
        <li><span>Confirm the betting amount.</span></li>
        <li><span>If you are willing to construct a parlay, you can keep adding more betting selections and input
            amounts.</span></li>
        <li><span>After finishing your betting, click on “Place Bet to Win.”</span></li>
      </ul>
      <img src="@/assets/introduction_1.jpg" alt="introducing how to install a sports betting game" class="intro_pics">
    </div>

    <section class="section_2">
      <h2>Advantages of GUGOBET Sports Betting</h2>
    </section>
    <div class="list">
      <ul class="list_2">
        <li><span>Both GUGOBET official website and the mobile app are available for sports betting.</span></li>
        <li><span>All popular matches are available;</span></li>
        <li><span>Competitive odds on each match;</span></li>
        <li><span>A wild range of betting markets are showcased;</span></li>
        <li><span>A user-friendly interface makes sports betting incredibly simple.</span></li>
        <li><span>Sport-related bonuses are available intermittently.</span></li>
        <li><span>Blog is provided with analysis and predictions.</span></li>
        <li><span>All currencies and payment methods displayed on the website are acceptable.</span></li>
        <li><span>Quick deposit and withdrawal.</span></li>
      </ul>
      <img src="@/assets/introduction_2.jpg" alt="introducing how to join a  sports betting game" class="intro_pics">
    </div>

    <section class="section_1">
      <h2></h2>
    </section>
    <section class="section_3">
      <a href="https://gacricket1.com/" target="_blank" rel="noopener noreferrer">
        <button class="button_bet">Place Sport Bets</button>
      </a>
    </section>
    <section class="section_1">
      <h2></h2>
    </section>
  </main>
</template>

<script>
export default {
  name: 'MainSection'
};
</script>

<style scoped>
main {
  padding: 1em;
  position: relative;
}

main::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgb(7, 31, 69);
  background-image: url('@/assets/background.jpg');
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  filter: brightness(75%);
}

.title {
  padding-top: 10em;
  padding-bottom: 5em;
}

.title h1 {
  display: flex;
  justify-content: center;
  padding: 0.2em;
  font-family: 'Trebuchet MS',
    'Lucida Sans Unicode',
    'Lucida Grande',
    'Lucida Sans',
    Arial,
    sans-serif;
  font-weight: bold;
  font-size: 4em;
  color: rgba(255, 255, 255);
}

.section_1 {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 60%;
  padding-bottom: 8em;
}

.section_1 p {
  width: 60%;
  font-family: 'Trebuchet MS',
    'Lucida Sans Unicode',
    'Lucida Grande',
    'Lucida Sans',
    Arial,
    sans-serif;
  font-size: 1.4em;
  color: rgba(255, 255, 255);
  letter-spacing: 0.02em;
  padding-right: 5em;
}

.button_bet {
  margin: 0 auto;
  background: radial-gradient(ellipse at 50% 80%, #0d3f68, #093152);
  background-color: #114978;
  color: white;
  font-weight: bold;
  padding: 0.8em 2.5em;
  border: none;
  border-radius: 1em;
  font-size: 1.3em;
  font-family: 'Trebuchet MS',
    'Lucida Sans Unicode',
    'Lucida Grande',
    'Lucida Sans',
    Arial,
    sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button_bet:hover {
  background: #156ab0;
}

.section_2 {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top: 8em;
  padding-bottom: 1em;
}

.section_2 h2 {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  letter-spacing: 0.05em;
  font-size: 3em;
  font-weight: normal;
  color: #2c96ec;
}

.section_3 {
  display: flex;
  width: 60%;
  justify-content: center;
  margin: 0 auto;
}

.section_3 p {
  font-family: 'Trebuchet MS',
    'Lucida Sans Unicode',
    'Lucida Grande',
    'Lucida Sans',
    Arial,
    sans-serif;
  font-size: 1.4em;
  color: rgba(255, 255, 255);
  letter-spacing: 0.04em;
  padding-bottom: 2em;
  padding-top: 3em;
}

.list {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.list_1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 70%;
  gap: 2em;
  margin: 0 auto;
  list-style-type: disc;
}

.list_1 li {
  list-style-position: inside;
  width: 70%;
  font-family: 'Lucida Sans',
    'Lucida Sans Regular',
    'Lucida Grande',
    'Lucida Sans Unicode',
    Geneva,
    Verdana,
    sans-serif;
  font-size: 2em;
  font-weight: 600;
  color: rgba(255, 255, 255);
  letter-spacing: 0.05em;
  padding-top: 1em;
}

.list_1 li::marker {
  color: #1577c7;
}

.list_2 {
  display: grid;
  width: 50%;
  padding-top: 3em;
  gap: 1em;
  margin: 0 auto;
}

.list_2 li {
  font-family: 'Lucida Sans',
    'Lucida Sans Regular',
    'Lucida Grande',
    'Lucida Sans Unicode',
    Geneva,
    Verdana,
    sans-serif;
  font-size: 1.3em;
  color: rgba(255, 255, 255);
  letter-spacing: 0.05em;
  padding-top: 0.5em;
}

.list_2 li::marker {
  color: #c9c614;
}

.game_pics {
  width: 18em;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0px 0px 1em #030e17d7;
  margin-top: 1em;
}

.intro_pics {
  margin: 0 auto;
  width: 25em;
  height: auto;
  border-radius: 5px;
  object-fit: cover;
  box-shadow: 0px 0px 1em #030e17d7;
  margin-top: 4em;
}
</style>