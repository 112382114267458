<template>
  <GlobalHeader />
  <RouterView />
  <GlobalFooter />
</template>

<script>
import GlobalHeader from './components/GlobalHeader.vue';
import GlobalFooter from './components/GlobalFooter.vue';

export default {
  name: 'App',
  components: {
    GlobalHeader,
    GlobalFooter
  },
  created() {
    document.title = "Skyrocket";
  }
};
</script>

<style>
* {
  margin: 0;
}
</style>